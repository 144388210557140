import axios from "axios";
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});

//Admin|auth
export const forgetPassword = (email) => API.post("/forgot-password/email", email);
export const resetPassword = (formData) => API.post("/forgot-password/confirm", formData);
export const login = (formData) => API.post("/login", formData);
export const logout = () => API.post("/logout");
export const refreshToken = () => API.get("/refresh-token");
export const setPassword = (formData) => API.post("/setup-password", formData);

// Admin|Profile
export const me = () => API.get("/me");
export const updateProfile = (data) => API.post("/update-profile", data);
export const changePassword = (data) => API.post("/change-password", data);
export const saveUserDevice = (data) => API.post("/save-device-token", data);
export const getAllNotifications = (data) => API.get("/notifications", { params: { ...data } });
export const readAllNotifications = () => API.get("/notifications-read-all");
export const readNotification = (notificationId) => API.get(`/notifications-read/${notificationId}`);

// Admin|Home
export const getAllBranches = () => API.get("/get-all-branches");
export const getAllCategories = () => API.get("/get-all-categories");
export const getAllCompanies = () => API.get("/get-all-companies");
export const getDays = () => API.get("/get-all-days");
export const getAllRejectReasons = () => API.get("/get-all-reasons-rejected");
export const getAllSubCategories = () => API.get("/get-all-sub-categories");
export const getHome = () => API.get(`/home`);

// Admin|recycables
export const fetchApproved = (data) => API.get("/collectable/approval/index", { params: { ...data } });
export const approveCollectables = (collectablesIdsArray) => API.post("/collectable/approved", collectablesIdsArray);
export const fetchArchived = (data) => API.get("/collectable/archived/index", { params: { ...data } });
export const bounceCollectable = (collectableId) => API.post(`/collectable/bounce`, collectableId);
export const fetchExpired = (data) => API.get("/collectable/expiring/index", { params: { ...data } });
export const rejectCollectables = (collectablesIdsArray) => API.post("/collectable/rejected", collectablesIdsArray);
export const fetchRejected = (data) => API.get("/collectable/rejected/index", { params: { ...data } });
export const reviveExpired = (collectablesIdsArray) => API.post("/collectable/revive-expiry-date", collectablesIdsArray);
export const getCollectable = (collectableId) => API.get(`/collectable/show/${collectableId}`);
export const fetchWaiting = (data) => API.get("/collectable/waiting/index", { params: { ...data } });

// Admin|Users|Admin
export const deleteUsersAdmins = (data) => API.delete("/users/admin/destroy", { data });
export const getUsersAdmins = (data) => API.get("/users/admin/index", { params: { ...data } });
export const createUsersAdmins = (newUser) => API.post("/users/admin/store", newUser);
export const UpdateUsersAdmins = (newUser) => API.post("/users/admin/update", newUser);

// Admin|Users|Branch
export const deleteUsersBranches = (data) => API.delete("/users/branch/destroy", { data });
export const getUsersBranches = (data) => API.get("/users/branch/index", { params: { ...data } });
export const createUsersBranches = (newUser) => API.post("/users/branch/store", newUser);
export const changeStatusUsersBranches = (data) => API.post("/users/branch/change-status", data);
export const resendEmailUsersBranches = (data) => API.post("/users/branch/resend-email-setup", data);
export const resetPasswordUsersBranches = (data) => API.post("/users/branch/reset-password", data);

// Admin|Users|Client
export const getClientCartItems = (data) => API.get("/users/client/cart-items", { params: { ...data } });
export const changeStatusUsersClients = (ClientsIdsArray) => API.post("/users/client/change-status", ClientsIdsArray);
export const getClientRecycables = (data) => API.get("/users/client/collectables", { params: { ...data } });
export const deleteUsersClients = (data) => API.delete("/users/client/destroy", { data });
export const getUsersClients = (data) => API.get("/users/client/index", { params: { ...data } });
export const getUsersClient = (clientId) => API.get(`/users/client/show/${clientId}`);
export const getClientTransactions = (data) => API.get("/users/client/transactions", { params: { ...data } });

//  Admin|Users|Recycler
export const changeActiveUsersRecyclers = (userIds) => API.post("/users/recycler/change-status", userIds);
export const deleteUsersRecyclers = (data) => API.delete("/users/recycler/destroy", { data });
export const getUsersRecyclers = (data) => API.get("/users/recycler/index", { params: { ...data } });
export const createUsersRecyclers = (newUser) => API.post("/users/recycler/store", newUser);
export const UpdateUsersRecyclers = (newUser) => API.post("/users/recycler/update", newUser);

//  Admin|Users|Recycler
export const changeActiveUsersCompanyAdmin = (userIds) => API.post("/users/company/change-status", userIds);
export const deleteUsersCompanyAdmin = (data) => API.delete("/users/company/destroy", { data });
export const getUsersCompanyAdmin = (data) => API.get("/users/company/index", { params: { ...data } });
export const createUsersCompanyAdmin = (newUser) => API.post("/users/company/store", newUser);
export const UpdateUsersCompanyAdmin = (newUser) => API.post("/users/company/update", newUser);

// Admin|Category
export const changeStatusCategory = (data) => API.post("/category/change-status", data);
export const deleteCategory = (data) => API.delete("/category/destroy", { data });
export const getCategories = (data) => API.get("/category/index", { params: { ...data } });
export const getCategory = (categoryId) => API.get(`/category/show/${categoryId}`);
export const createCategory = (data) => API.post("/category/store", data, {headers: { "Content-Type": "multipart/form-data" }});
export const updateCategory = (data) => API.post("/category/update", data, {headers: { "Content-Type": "multipart/form-data" }});

// Admin|Sub-category
export const deleteSubCategory = (data) => API.delete("/sub-category/destroy", { data });
export const getSubCategories = (data) => API.get("/sub-category/index", { params: { ...data } });
export const createSubCategory = (data) => API.post("/sub-category/store", data);
export const updateSubCategory = (data) => API.post("/sub-category/update", data);

// Admin|Brand
export const deleteBrand = (data) => API.delete("/brand/destroy", { data });
export const getBrands = (data) => API.get("/brand/index", { params: { ...data } });
export const createBrand = (data) => API.post("/brand/store", data);
export const updateBrand = (data) => API.post("/brand/update", data);

// Admin|Conditions
export const deleteCondition = (data) => API.delete("/condition-points/destroy", { data });
export const getConditions = (data) => API.get("/condition-points/index", { params: { ...data } });
export const createCondition = (data) => API.post("/condition-points/store", data);
export const updateCondition = (data) => API.post("/condition-points/update", data);

// Admin|Voucher
export const addNewCodes = (data) => API.post("/voucher/addition-codes", data);
export const addAmount = (data) => API.post("/voucher/addition-new-amount", data);
export const voucherAnalysis = (voucherId) => API.get(`/voucher/show-items/${voucherId}`);
export const assignCategoriesBranches = (data) => API.post("/voucher/assign-categories-branches", data);
export const voucherChangeStatus = (data) => API.post("/voucher/change-status", data);
export const deleteVoucher = (data) => API.delete("/voucher/destroy", { data });
export const deleteExpiredItems = (voucher_id) => API.delete(`/voucher/destroy-expired-items/${voucher_id}`);
export const deleteVoucherItems = (data) => API.delete("/voucher/destroy-items", { data });
export const deleteNotUsedItems = (voucher_id) => API.delete(`/voucher/destroy-not-used-items/${voucher_id}`);
export const getBranchesByCategories = (data) => API.post("/voucher/get-branches-by-category", data);
export const getVouchers = (data) => API.get("/voucher/index", { params: { ...data } });
export const voucherRedeem = (data) => API.post("/voucher/redeem", data);
export const voucherSearch = (data) => API.post("/voucher/search", data);
export const getVoucherItems = (data) => API.get(`/voucher/show-items/${data.voucher_id}`, { params: { ...data } });
export const getVoucher = (voucher_id) => API.get(`/voucher/show/${voucher_id}`);
export const createVoucher = (data) => API.post("/voucher/store", data, {headers: { "Content-Type": "multipart/form-data" }});
export const updateVoucher = (data) => API.post("/voucher/update", data, {headers: { "Content-Type": "multipart/form-data" }});

// Admin|Company
export const getCompanyAnalysisCollectables = (companyId) => API.get(`/company/analysis-collectables/${companyId}`);
export const deleteCompany = (data) => API.delete("/company/destroy", { data });
export const getCompanyCollectables = (data) => API.get(`/company/get-all-collectables/${data.company_id}`, {params: { ...data }});
export const getCompanies = (data) => API.get("/company/index", { params: { ...data } });
export const getCompany = (companyId) => API.get(`/company/show/${companyId}`);
export const createCompany = (companyData) => API.post("/company/store", companyData, {headers: { "Content-Type": "multipart/form-data" }});
export const updateCompany = (companyData) => API.post("/company/update", companyData, {headers: { "Content-Type": "multipart/form-data" }});

// Admin|Branch
export const changeStatusBranch = (branchIds) => API.post("/branch/change-status", branchIds);
export const getBranchCollectablesArchivedBatches = (data) => API.get(`/branch/collectables-archived/${data.branch_id}`, {params: { ...data }});
export const getBranchCollectablesArchivedItems = (data) => API.get(`/branch/collectables-archived-items/${data.archived_collectables_id}`,{params: { ...data }});
export const branchCollectablesCollectedPdf = (id) => API.get(`/branch/collectables-collected-pdf/${id}`);
export const getBranchCollectablesCollected = (data) => API.get(`/branch/collectables-collected/${data.branch_id}`, {params: { ...data }});
export const getBranchCollectablesNotCollected = (data) => API.get(`/branch/collectables-not-collected/${data.branch_id}`, {params: { ...data }});
export const deleteBranch = (data) => API.delete("/branch/destroy", { data });
export const getBranches = (data) => API.get("/branch/index", { params: { ...data } });
export const resetBranch = (branchIds) => API.post("/branch/reset-collectables", branchIds);
export const getBranch = (branchId) => API.get(`/branch/show/${branchId}`);
export const createBranch = (branchData) => API.post("/branch/store", branchData, {headers: { "Content-Type": "multipart/form-data" }});
export const updateBranch = (branchData) => API.post("/branch/update", branchData, { headers: { "Content-Type": "multipart/form-data" }});

// pickup | location | states
export const changeStatusState = (data) => API.post("/state/change-status", data);
export const deleteState = (data) => API.delete("/state/destroy", { data });
export const getStates = (data) => API.get("/state/index", { params: { ...data } });
export const createState = (data) => API.post("/state/store", data);
export const updateState = (data) => API.post("/state/update", data);

// pickup | location | cities
export const changeStatusCity = (data) => API.post("/city/change-status", data);
export const deleteCity = (data) => API.delete("/city/destroy", { data });
export const getCities = (data) => API.get("/city/index", { params: { ...data } });
export const createCity = (data) => API.post("/city/store", data);
export const updateCity = (data) => API.post("/city/update", data);
export const getAllStates = () => API.get("/get-all-states");

// pickup | location | districts
export const changeStatusDistrict = (data) => API.post("/district/change-status", data);
export const deleteDistrict = (data) => API.delete("/district/destroy", { data });
export const getDistricts = (data) => API.get("/district/index", { params: { ...data } });
export const createDistrict = (data) => API.post("/district/store", data);
export const updateDistrict = (data) => API.post("/district/update", data);
export const getAllCities = () => API.get("/get-all-cities");

// Admin|Transactions
export const failedSend = (data) => API.post("/transaction/failed-sent", data);
export const getTransactions = (data) => API.get("/transaction/index", { params: { ...data } });
export const successfulSend = (data) => API.post("/transaction/successfully-sent", data);
export const getWaitingTransactions = (data) => API.get("/transaction/waiting/index", { params: { ...data } });

// Admin | Carousels
export const changeStatusCarousel = (data) => API.post("/carousel/change-status", data);
export const deleteCarousel = (data) => API.delete("/carousel/destroy", { data });
export const getCarousels = (data) => API.get("/carousel/index", { params: { ...data } });
export const getCarousel = (carouselId) => API.get(`/carousel/show/${carouselId}`);
export const createCarousel = (data) => API.post("/carousel/store", data, { headers: { "Content-Type": "multipart/form-data" }});
export const updateCarousel = (data) => API.post("/carousel/update", data, { headers: { "Content-Type": "multipart/form-data" }});

// Admin | tutorials
export const changeStatusTutorial = (data) => API.post("/tutorial/change-status", data);
export const deleteTutorial = (data) => API.delete("/tutorial/destroy", { data });
export const getTutorials = (data) => API.get("/tutorial/index", { params: { ...data } });
export const getTutorial = (tutorialId) => API.get(`/tutorial/show/${tutorialId}`);
export const createTutorial = (data) => API.post("/tutorial/store", data, { headers: { "Content-Type": "multipart/form-data" }});
export const updateTutorial = (data) => API.post("/tutorial/update", data, { headers: { "Content-Type": "multipart/form-data" }});

// Admin | RedeemCompanies
export const changeStatusRedeemCompany = (data) => API.post("/redeem-company/change-status", data);
export const deleteRedeemCompany = (data) => API.delete("/redeem-company/destroy", { data });
export const getRedeemCompanies = (data) => API.get("/redeem-company/index", { params: { ...data } });
export const getRedeemCompany = (compId) => API.get(`/redeem-company/show/${compId}`);
export const createRedeemCompany = (data) => API.post("/redeem-company/store", data, { headers: { "Content-Type": "multipart/form-data" }});
export const updateRedeemCompany = (data) => API.post("/redeem-company/update", data, { headers: { "Content-Type": "multipart/form-data" }});

// Admin|Settings
export const getSettings = () => API.get(`/settings/show`);
export const updateSettings = (data) => API.post("/settings/update", data);

// Admin|Update-Version
export const getMobileUpdate = () => API.get(`/updates-versions/show`);
export const updateMobileData = (data) => API.post("/updates-versions/update", data);

//Admin|Archived-Batches
export const getWaitingArchivedBatches = (data) =>  API.get("/branch/collectables-archived/waiting", { params: { ...data }});
export const getApprovedArchivedBatches = (data) => API.get("/branch/collectables-archived/approval", { params: { ...data }});
export const getCollectedArchivedBatches = (data) => API.get("/branch/collectables-archived/collected", { params: { ...data }});
export const getRejectedArchivedBatches = (data) => API.get("/branch/collectables-archived/reject", { params: { ...data }});
export const getRecyclersList = () => API.get("/branch/recyclers");
export const getArchivedBatchItems = (data) => API.get( `/branch/collectables-archived-items/${data.archived_collectables_id}`, { params: { ...data }});

export const getArchivedBatch = (data) => API.get( `/branch/collectables-archived/show/${data.archived_collectables_id}`);
export const approveBatch = (data) => API.post("/branch/collectables-archived/approved", data);
export const assignRecycler = (data) => API.post("/branch/collectables-archived/assign-recycler", data);
export const createArchivedBatch = (data) => API.post("/branch/collectables-archived/create", data);
export const rejectBatch = (data) => API.post("/branch/collectables-archived/rejected", data);

//manager & agent
export const getManagerHome = () => API.get(`/branch/home`);

export const managerExportArchive = (paData) => API.post("/branch/collectable/archive/export", paData);
export const managerFetchArchivedBatches = (data) => API.get("/branch/collectable/archived/index", { params: { ...data } });
export const managerFetchArchivedItems = (data) => API.get(`/branch/collectable/archived/items/${data.archived_collectables_id}`,{params: { ...data }});
export const managerGenerateCode = (id) => API.get(`/branch/collectable/archived/collected/generate-code/${id}`);
export const managerCollect = (id) => API.post("/branch/collectable/collected", id);
export const managerFetchCollected = (data) => API.get("/branch/collectable/index", { params: { ...data } });
export const managerSearchCode = (data) => API.get("/branch/collectable/search", { params: { ...data } });
export const getCollectableManager = (collectableId) => API.get(`/branch/collectable/show/${collectableId}`);
// export const managerArchiveAll = (passData) =>
//   API.post("/branch/collectable/archived", passData);

// export const managerFetchSupport = (paData) =>
//   API.post("/branch/support/index", paData);
// export const managerCreateNewSupport = (newData) =>
//   API.post("/branch/support/store", newData);

export const managerVoucherSearch = (code) => API.post("/branch/voucher/search", code);
export const managerVoucherIsUsed = (code) => API.post("/branch/voucher/redeem", code);

// manager only
export const managerDeleteUsers = (data) => API.delete("/branch/agents/destroy", { data });
export const managerFetchUsers = (data) => API.get("/branch/agents/index", { params: { ...data } });
export const managerCreateNewUser = (newUser) => API.post("/branch/agents/store", newUser);



// Recycler

export const getArchivedApprovedRecycler = (data) => API.get("/branch/archived-collectables/approval", { params: { ...data }});
export const getArchivedCollectedRecycler = (data) => API.get("/branch/archived-collectables/collected", { params: { ...data }});
export const getArchivedWaitingRecycler = (data) =>  API.get("/branch/archived-collectables/waiting", { params: { ...data }});
export const getCollectableRecycler = (collectableId) => API.get(`/collectable/show/${collectableId}`);
export const getArchivedItemsRecycler = (data) => API.get(`/branch/collectables-archived-items/${data.archived_collectables_id}`,{params: { ...data }});

export const approveBatchRecycler = (data) => API.post("/branch/archived-collectables/approved", data);
export const collectBatchRecycler = (data) => API.post("/branch/archived-collectables/collected-request", data);
export const rejectBatchRecycler = (data) => API.post("/branch/archived-collectables/rejected", data);

// export const getBranchesRecycler = (data) => API.get("/branch/index", { params: { ...data } });
// export const getBranchCollectablesArchivedBatchesRecycler = (data) => API.get(`/branch/collectables-archived/${data.branch_id}`, {params: { ...data }});



// Company Admin requests
export const getCompanyAdminHome = () => API.get(`/company-user/branch/analytics`);
export const getCompanyAdminAllBranches = () => API.get(`/company-user/get-branches`);
// Branch
export const getBranchArchivedItemsCompanyAdmin = (data) => API.get(`/company-user/branch/collectables-archived-items/${data.archived_collectables_id}`,{params: { ...data }});
export const getBranchArchivedBatchesCompanyAdmin = (data) => API.get(`/company-user/branch/collectables-archived/${data.branch_id}`, {params: { ...data }});
export const getArchivedBatchCompanyAdmin = (data) => API.get( `/company-user/branch/collectables-archived/show/${data.archived_collectables_id}`);
export const branchCollectedPdfCompanyAdmin = (data) => API.post(`/company-user/branch/collectables-collected-pdf`,data);
export const getBranchCollectedCompanyAdmin = (data) => API.get(`/company-user/branch/collectables-collected/${data.branch_id}`, {params: { ...data }});
export const getBranchNotCollectedCompanyAdmin = (data) => API.get(`/company-user/branch/collectables-not-collected/${data.branch_id}`, {params: { ...data }});
export const getBranchesCompanyAdmin = (data) => API.get("/company-user/branch/index", { params: { ...data } });
export const getBranchCompanyAdmin = (branchId) => API.get(`/company-user/branch/show/${branchId}`);
export const getCollectableCompanyAdmin = (collectableId) => API.get(`/company-user/collectable/show/${collectableId}`);

export const getCompanyDeletedUsers = (data) => API.get("/company-user/logs", { params: { ...data } });

// User Branch
export const deleteCompanyBranchUsers = (data) => API.delete("/company-user/users/branch/destroy", { data });
export const getCompanyBranchUser = (data) => API.get("/company-user/users/branch/index", { params: { ...data } });
export const createCompnayBranchUser = (newUser) => API.post("/company-user/users/branch/store", newUser);
export const changeStatusCompanyBranchUsers = (data) => API.post("/company-user/users/branch/change-status", data);
export const resendEmailCompanyBranchUsers = (data) => API.post("/company-user/users/branch/resend-email-setup", data);
export const resetPasswordCompanyBranchUsers = (data) => API.post("/company-user/users/branch/reset-password", data);

// User Company Admin
export const deleteCompanyAdminCompany = (data) => API.delete("/company-user/users/destroy", { data });
export const getCompanyAdminCompany = (data) => API.get("/company-user/users/index", { params: { ...data } });
export const createCompanyAdminCompany = (newUser) => API.post("/company-user/users/store", newUser);


// report
export const companyAdminExportReport = (data) => API.post(`/company-user/branch/collectables-export-report`,data);